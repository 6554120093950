export const advantages = [
    {
        title: "Мы предоставляем гарантии",
        info: "Все трудовые и налоговые риски мы полностью берем на себя. Мы готовы предоставить вам полное юридическое сопровождение в процессе сотрудничества",
        imgName: "garant.png",
    },
    {
        title: "Оформление по договору",
        info: "Все сотрудники с документами и оформлены в штат (гарантируем наличие всех документов по договору)",
        imgName: "dogovar.jpg",
    },
    {
        title: "Квалифицированные исполнители",
        info: "Все наши исполнители специалисты своей области. Поэтому мы уверены, что вы получите именно то, чего ожидаете. Мы проверяем квалификацию персонала, проводим тестирование и аттестацию кандидатов",
        imgName: "pers.jpg",
    },
    {
        title: "Ежедневная доступность",
        info: "Вы имеете возможность ежедневно связаться с нами и решить любые вопросы. Вы можете просто позвонить и обсудить все подробности для оказания услуги.",
        imgName: "24.png",
    },
    {
        title: "Мы подстроимя под ваш график",
        info: "Благодаря большому штату и опыту, мы способны адаптироваться под требования заказчиков.",
        imgName: "float.jpg",
    },
    {
        title: "Оперативность замены",
        info: "Обеспечим 100% бесперебойность работы персонала и сохранность денежных средств. В случае необходимости, быстро можем заменить работников на других",
        imgName: "replace.jpg",
    },
    {
        title: "Интерес к долгосрочным отношениям",
        info: "Мы ориентированы на долгосрочное сотрудничество. Мы уверены в своём качестве, и уверены что вам понравится работать с нами!",
        imgName: "relations.png",
    },
    {
        title: "Наша цена и качество",
        info: "У нас оптимальное соотношение цены и качества. Наши услуги отличаются надежностью и безопасностью.",
        imgName: "quality.jpg",
    },
];


export const staffs = [
    {
        imgName: "gr2.jpg",
        title: "Грузчики",
        text: `Мы гарантируем что наши грузчики выполнят все работы в срок.
                Мы предлагаем Вам услугу грузчиков, которая позволит Вам сократить время на переезд или перенос ваших вещей.
                Наши грузчики оперативно приедут к Вам на адрес, и быстро все перенесут.
                Если у Вас есть своя машина, наши грузчики помогут Вам погрузить вещи в машину.`,
        price: 310,
    },
    {
        imgName: "raznor.jpg",
        title: "Разнорабочие",
        text: `Разнорабочие выполнят все необходимые работы. Выполним любые работы по дому, даче, офису.
Погрузка/разгрузка фур, контейнеров, вагонов, разгрузка газелей, квартирные и офисные переезды, вывоз строительного мусора и т.п. Демонтажные работы любой сложности.`,
        price: 290,
    },
    {
        imgName: "klining.jpg",
        title: "Клининг",
        text: "Идеальную частоту помещения обеспечит клининговые услуги, которые проводятся специалистами в области клининга.\n" +
            "Если же вы не хотите тратить время и силы на уборку, то лучше заказать услугу по уборке квартиры на дом, которая обойдется вам намного дешевле.",
        price: 300,
    },
    {
        imgName: "fas.jpg",
        title: "Фасовщики",
        text: `Благодаря фасовщикам и упаковщикам можно быстро и качественно производить упаковку различных продуктов питания, медицинских препаратов в герметичные контейнеры, и т.п.
В процессе работы эти люди применяют различные упаковочные материалы: ленты, пакеты, коробки, мешки и т.д. Фасовщики и упаковщики должны обладать высокой квалификацией и навыками, чтобы профессионально выполнять свою работу, поэтому такие специалисты всегда востребованы`,
        price: 260,
    },
    {
        imgName: "rabotniki-torgovogo-zala.jpg",
        title: "Работники торгового зала",
        text: "Работники торгового зала - это люди, которые следят за порядком в зале, помогают покупателям с выбором, принимают и выкладывают товар на полки.\n" +
            "Они обладают навыками общения с людьми, знают, какое именно количество товара необходимо на полках магазина.",
        price: 270,
    },
    {
        imgName: "slesar-1.jpg",
        title: "Слесари",
        text: "Слесари выполняют работу по ремонту, монтажу, демонтажу, техническому обслуживанию и ремонту оборудования. Они знают: назначение, устройство и правила подналадки обслуживаемого оборудования; способы монтажа, демонтажа и ремонта промышленного оборудования; Технические требования, предъявляемые к сырью и материалам.",
        price: 310,
    },
];

export const typesShifts = [
    {
        title: '8 часов (+1)',
        hours: 8,
    },
    {
        title: '11 часов (+1)',
        hours: 12,
    },
    {
        title: 'Индивидуальный график',
        hours: 9,
    },
]
